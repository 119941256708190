var exports = {};
var GlobalPromise = typeof Promise !== "undefined" ? Promise // eslint-disable-line no-undef
: null;

var ExtendedPromise =
/** @class */
function () {
  function ExtendedPromise(options) {
    var _this = this;

    if (typeof options === "function") {
      this._promise = new ExtendedPromise.Promise(options);
      return;
    }

    this._promise = new ExtendedPromise.Promise(function (resolve, reject) {
      _this._resolveFunction = resolve;
      _this._rejectFunction = reject;
    });
    options = options || {};
    this._onResolve = options.onResolve || ExtendedPromise.defaultOnResolve;
    this._onReject = options.onReject || ExtendedPromise.defaultOnReject;

    if (ExtendedPromise.shouldCatchExceptions(options)) {
      this._promise.catch(function () {// prevents unhandled promise rejection warning
        // in the console for extended promises that
        // that catch the error in an asynchronous manner
      });
    }

    this._resetState();
  }

  ExtendedPromise.defaultOnResolve = function (result) {
    return ExtendedPromise.Promise.resolve(result);
  };

  ExtendedPromise.defaultOnReject = function (err) {
    return ExtendedPromise.Promise.reject(err);
  };

  ExtendedPromise.setPromise = function (PromiseClass) {
    ExtendedPromise.Promise = PromiseClass;
  };

  ExtendedPromise.shouldCatchExceptions = function (options) {
    if (options.hasOwnProperty("suppressUnhandledPromiseMessage")) {
      return Boolean(options.suppressUnhandledPromiseMessage);
    }

    return Boolean(ExtendedPromise.suppressUnhandledPromiseMessage);
  }; // start Promise methods documented in:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise#Methods


  ExtendedPromise.all = function (args) {
    return ExtendedPromise.Promise.all(args);
  };

  ExtendedPromise.allSettled = function (args) {
    return ExtendedPromise.Promise.allSettled(args);
  };

  ExtendedPromise.race = function (args) {
    return ExtendedPromise.Promise.race(args);
  };

  ExtendedPromise.reject = function (arg) {
    return ExtendedPromise.Promise.reject(arg);
  };

  ExtendedPromise.resolve = function (arg) {
    return ExtendedPromise.Promise.resolve(arg);
  };

  ExtendedPromise.prototype.then = function () {
    var _a;

    var args = [];

    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }

    return (_a = this._promise).then.apply(_a, args);
  };

  ExtendedPromise.prototype.catch = function () {
    var _a;

    var args = [];

    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }

    return (_a = this._promise).catch.apply(_a, args);
  };

  ExtendedPromise.prototype.resolve = function (arg) {
    var _this = this;

    if (this.isFulfilled) {
      return this;
    }

    this._setResolved();

    ExtendedPromise.Promise.resolve().then(function () {
      return _this._onResolve(arg);
    }).then(function (argForResolveFunction) {
      _this._resolveFunction(argForResolveFunction);
    }).catch(function (err) {
      _this._resetState();

      _this.reject(err);
    });
    return this;
  };

  ExtendedPromise.prototype.reject = function (arg) {
    var _this = this;

    if (this.isFulfilled) {
      return this;
    }

    this._setRejected();

    ExtendedPromise.Promise.resolve().then(function () {
      return _this._onReject(arg);
    }).then(function (result) {
      _this._setResolved();

      _this._resolveFunction(result);
    }).catch(function (err) {
      return _this._rejectFunction(err);
    });
    return this;
  };

  ExtendedPromise.prototype._resetState = function () {
    this.isFulfilled = false;
    this.isResolved = false;
    this.isRejected = false;
  };

  ExtendedPromise.prototype._setResolved = function () {
    this.isFulfilled = true;
    this.isResolved = true;
    this.isRejected = false;
  };

  ExtendedPromise.prototype._setRejected = function () {
    this.isFulfilled = true;
    this.isResolved = false;
    this.isRejected = true;
  };

  ExtendedPromise.Promise = GlobalPromise;
  return ExtendedPromise;
}();

exports = ExtendedPromise;
export default exports;